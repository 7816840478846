@font-face {
	font-family: nunitosans-bold;
	src: url("/static/font/nunitosans/nunitosans-bold-webfont.woff2") format("woff2"),
		url("/static/font/nunitosans/nunitosans-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: nunitosans-regular;
	src: url("/static/font/nunitosans/nunitosans-regular-webfont.woff2") format("woff2"),
		url("/static/font/nunitosans/nunitosans-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: nunitosans-black;
	src: url("/static/font/nunitosans/nunitosans-black-webfont.woff2") format("woff2"),
		url("/static/font/nunitosans/nunitosans-black-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: nunitosans-extrabold;
	src: url("/static/font/nunitosans/nunitosans-ExtraBold.woff2") format("woff2"),
		url("/static/font/nunitosans/nunitosans-ExtraBold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
