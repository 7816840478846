:root {
	--color-primary-100: #e1f3fc;
	--color-primary-200: #afe0f7;
	--color-primary-300: #72c9f2;
	--color-primary-400: #4ac6ff;
	--color-primary-500: #36b2ec;
	--color-primary-600: #138dc7;
	--color-primary-700: #003870;
	--color-primary-800: #002243;

	--color-secondary-100: #f4ebd7;
	--color-secondary-200: #e3cv9a;
	--color-secondary-300: #d8b872;
	--color-secondary-400: #e3af40;
	--color-secondary-500: #c79935;
	--color-secondary-600: #b28420;
	--color-secondary-700: #836423;
	--color-secondary-800: #6f500f;

	--color-black: #000000;
	--color-gray-300: #f7f7f7;
	--color-gray-400: #e9e9e9;
	--color-gray-500: #bbbbbb;
	--color-gray-600: #636363;
	--color-gray-700: #393939;

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// footer
	--color-background-footer: var(--color-gray-600);
	--color-text-footer: white;

	// badge inclus
	--color-background-badge-inclus: var(--color-primary-500);
	--color-text-badge-inclus: white;

	// push notification
	--color-background-push-notification: var(--color-primary-600);

	// active color
	--color-active: var(--color-primary-600);

	// payment banner
	--payment-description-background-color: var(--color-secondary-100);
	--payment-description-instalments-background-color: var(--color-secondary-500);

	// choice
	--choice-background-color: var(--color-gray-700);

	// input-label
	--input-label-active-color: var(--color-primary-500);

	// picture shadow
	--picture-shadow-layer: rgba(
		0,
		34,
		67,
		0.3
	); // rgba(var(--color-primary-800, 0.3) ne fonctionne pas car CSS ne convertit pas directement
	// les couleurs hex en RGB. Utilisation manuelle de la valeur RGB équivalente.

	// membership bloc
	--account-bg-membership: linear-gradient(0deg, #393939 0%, #636363 100%);

	// credit bloc
	--gradient-credit-color: linear-gradient(90deg, #836423 0%, #c79935 100%);

	--color-text-loyalty: var(--color-gray-700);

	//tabs
	--tab-active-color: var(--color-primary-700);
}
